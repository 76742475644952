import React from 'react'
import { useSiteMetadata } from '../hooks/use-site-metadata'
import { getCurrentLangKey } from 'ptz-i18n'

const NotFoundPage = () => {
  const { langs, defaultLangKey } = useSiteMetadata().languages
  if (typeof window !== 'undefined') {
    const url = location.pathname
    const langKey = getCurrentLangKey(langs, defaultLangKey, url)
    window.location = `/${langKey}/`;
  }

  return null
}

export default NotFoundPage
